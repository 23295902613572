import { language } from "shared/language";

export const PERMISSION_EXISTED = language.permission_existed;
export const PERMISSION_NOT_FOUND = language.permission_not_found;

export const GROUP_PERMISSION_EXISTED = language.group_permission_existed;
export const GROUP_PERMISSION_NOT_FOUND = language.group_permission_not_found;

export const MODULE_CATEGORY_CODE_EXISTED = language.code_group_module_existed;
export const MODULE_CODE_EXISTED = language.code_module_existed;
export const LIMIT_CODE_EXISTED = language.code_limit_existed;
