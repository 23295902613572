import Loading from "components/Loading";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import PermissionDeniedPage from "components/PermissionDenied";
import Permission from "components/Permission";
import { MODULE_REMOTE_CONFIG } from "shared/constants";

const RemoteConfig = React.lazy(
  () => import("containers/AppManagement/RemoteConfig")
);

function RemoteConfigRoute() {
  return (
    <Route
      path="remote-config"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<RemoteConfig />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_REMOTE_CONFIG]}
          />
        </Suspense>
      }
    />
  );
}

export default RemoteConfigRoute;
