import { language } from "shared/language";
import { convertArrToObj } from "shared/utils";

export const BUILDING_DEFAULT_FILTER = {
  name: undefined,
  id: undefined,
  status: undefined,
  districtId: undefined,
  cityId: undefined,
  wardId: undefined,
};

export const BLOCK = "BLOCK";
export const BUILDING = "BUILDING";
export const AREA = "AREA";

export const BUILDING_TYPES = [
  {
    id: BLOCK,
    key: BLOCK,
    name: language.block,
  },
  {
    id: BUILDING,
    key: BUILDING,
    name: language.building,
  },
  {
    id: AREA,
    key: AREA,
    name: language.area,
  },
];

export const CONFIG_ICON = "CONFIG_ICON";
export const CONFIG_PAYMENT = "CONFIG_PAYMENT";
export const CONFIG_MODULE = "CONFIG_MODULE";

export const CONFIG_BUILDING_MAIN_MENUS = [
  {
    label: language.payment_config,
    value: CONFIG_PAYMENT,
  },
  {
    label: language.config_module,
    value: CONFIG_MODULE,
  },
];

export const BUILDING_TYPE_BY_ID = convertArrToObj(BUILDING_TYPES, "id");

export const BUILDING_TEXT = "BUILDING";

export const BUILDING_ACTIVE = "ACTIVE";
export const BUILDING_DISABLED = "DISABLED";

export const BUILDING_STATUS_BY_ID = {
  ACTIVE: { id: BUILDING_ACTIVE, name: language.active },
  DISABLED: { id: BUILDING_DISABLED, name: language.inactive },
};

export const APP_RESIDENT_WHITE_LABEL = "APP_RESIDENT_WHITE_LABEL";
export const APP_RESIDENT = "APP_RESIDENT";
