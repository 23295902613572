import moment, { Moment } from "moment";

const convertNumberToDateTime = (timeInt: number) => {
  const result = { time: "", minute: "", hour: "" } as {
    time: string;
    minute: number | undefined | string;
    hour: number | undefined | string;
    dateTime: Moment;
  };
  if (timeInt === 0) {
    result.time = "00:00";
    result.dateTime = moment(`${result.hour}:${result.minute}`, "HH:mm");
    return {
      hour: "00",
      minute: "00",
      time: "00:00",
      dateTime: moment(`00:00`, "HH:mm"),
    };
  }
  if (timeInt) {
    result.minute = timeInt % 100;
    result.hour = (timeInt - result.minute) / 100;
    const closeMinuteString = result.minute.toString().padStart(2, "0");
    const closeHourString = result.hour.toString().padStart(2, "0");
    result.time = `${closeHourString}:${closeMinuteString}`;
    result.dateTime = moment(`${result.hour}:${result.minute}`, "HH:mm");
  }
  return result;
};

export default convertNumberToDateTime;
