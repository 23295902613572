import { language } from "shared/language";
import { convertArrToObj } from "shared/utils";

export const MERCHANT_DEFAULT_FILTER = {
  name: undefined,
  displayName: undefined,
  phone: undefined,
  location: undefined,
};

export const MERCHANT_PAYMENT_METHOD_CASH = "CASH";

export const MERCHANT_PAYMENT_METHOD_ARRAY = [
  {
    id: MERCHANT_PAYMENT_METHOD_CASH,
    key: MERCHANT_PAYMENT_METHOD_CASH,
    name: language.cash_payment,
  },
];

export const MERCHANT_PAYMENT_METHOD_BY_ID = convertArrToObj(
  MERCHANT_PAYMENT_METHOD_ARRAY,
  "id"
);
