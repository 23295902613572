import { APP_PROJECT_CYHOME } from "./appProjectConfig";

export const SERVER = process.env.REACT_APP_API_CMS_URL;
export const SERVER_PREMIUM = process.env.REACT_APP_API_PREMIUM_URL;
export const COOKIE_TAG = process.env.REACT_APP_COOKIE_TAG || "cyhome";
export const DEFAULT_COUNTRY_CALLING_CODE =
  process.env.REACT_APP_COUNTRY_CALLING_CODE || "+84";
export const PAGINATION_LIMIT = +(
  process.env.REACT_APP_PAGINATION_LIMIT || "20"
);

export const ENV = process.env.REACT_APP_ENV || "development";
export const DEEP_LINK_TYPE =
  process.env.REACT_APP_DEEP_LINK_TYPE || "cyhome-dev://";

export const REACT_APP_MODULE_LOAD_SECONDS =
  (process.env.REACT_APP_MODULE_LOAD_SECONDS &&
    +process.env.REACT_APP_MODULE_LOAD_SECONDS) ||
  3000;

export const APP_PROJECT: string =
  process.env.REACT_APP_PROJECT || APP_PROJECT_CYHOME;
