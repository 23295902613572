import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_MERCHANT_ORDER } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const MerchantOrder = React.lazy(
  () => import("containers/MerchantManagement/MerchantOrder")
);

function MerchantOrderRoute() {
  return (
    <Route
      path="merchant-order"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<MerchantOrder />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_MERCHANT_ORDER]}
          />
        </Suspense>
      }
    />
  );
}

export default MerchantOrderRoute;
