import { language } from "shared/language";

export const USER_EXISTED = language.user_exist;
export const USER_NOT_HAVE_PERMISSION =
  language.you_do_not_have_permission_to_use_this_action;

export const USER_NOT_HAVE_ROLE =
  language.you_are_not_authorized_please_contact_the_administrator;

export const USER_EMAIL_NOT_FOUND = language.user_email_not_found;
