import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import PermissionDeniedPage from "components/PermissionDenied";
import { MODULE_MERCHANT } from "shared/constants";

const Merchant = React.lazy(
  () => import("containers/MerchantManagement/Merchant")
);

const MerchantRoute = () => {
  return (
    <Route
      path="merchant"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<Merchant />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_MERCHANT]}
          />
        </Suspense>
      }
    />
  );
};

export default MerchantRoute;
