import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_BILL_LOCK } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const BillLock = React.lazy(
  () => import("containers/OperationalManagement/BillLock")
);

function BillLockRoute() {
  return (
    <Route
      path="bill-lock"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<BillLock />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_BILL_LOCK]}
          />
        </Suspense>
      }
    />
  );
}

export default BillLockRoute;
