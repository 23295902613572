import { language } from "shared/language";
import { convertArrToObj } from "shared/utils";
export const MERCHANT_ORDER_DEFAULT_FILTER = {
  code: undefined,
  utilityName: undefined,
  utilityPartnerId: undefined,
  statuses: undefined,
  createdAt: undefined,
  startDateUsage: undefined,
  endDateUsage: undefined,
};

export const BOOKING_STATUS_PENDING = "PENDING";
export const BOOKING_STATUS_WAITING_FOR_CANCELLED = "WAITING_FOR_CANCELLED";
export const BOOKING_STATUS_WAITING_FOR_COMPLETED = "WAITING_FOR_COMPLETED";
export const BOOKING_STATUS_APPROVED = "APPROVED";
export const BOOKING_STATUS_COMPLETED = "COMPLETED";
export const BOOKING_STATUS_CANCELLED = "CANCELLED";

export const STATUS_MERCHANT_ORDER = [
  {
    value: BOOKING_STATUS_PENDING,
    name: language.booking_status_pending,
    color: "blue",
  },
  {
    value: BOOKING_STATUS_WAITING_FOR_CANCELLED,
    name: language.booking_waiting_for_cancelled,
    color: "blue",
  },

  {
    value: BOOKING_STATUS_WAITING_FOR_COMPLETED,
    name: language.booking_waiting_for_completed,
    color: "blue",
  },

  {
    value: BOOKING_STATUS_APPROVED,
    name: language.booking_status_approve,
    color: "blue",
  },

  {
    value: BOOKING_STATUS_COMPLETED,
    name: language.booking_status_completed,
    color: "green",
  },
  {
    value: BOOKING_STATUS_CANCELLED,
    name: language.booking_status_cancelled,
    color: "red",
  },
];
export const STATUS_MERCHANT_ORDER_OBJ = convertArrToObj(
  STATUS_MERCHANT_ORDER,
  "value"
);

export const PAYMENT_METHOD_ONLINE = "ONLINE";
export const PAYMENT_METHOD_CHECKOUT = "CHECKOUT";
export const PAYMENT_METHOD_ALL = "ALL";
export const PAYMENT_METHOD_SPOS_CASH = "CASH";
export const PAYMENT_METHOD_SPOS_CARD = "SPOSCARD";
export const PAYMENT_METHOD_SPOS_QRCODE = "SPOS_QRCODE";

export const PAYMENT_METHOD_MERCHANT_ORDER = [
  {
    value: PAYMENT_METHOD_ONLINE,
    name: language.online,
  },
  {
    value: PAYMENT_METHOD_CHECKOUT,
    name: language.checkout,
  },
  {
    value: PAYMENT_METHOD_ALL,
    name: language.all,
  },
  {
    value: PAYMENT_METHOD_SPOS_CASH,
    name: language.spos_cash,
  },
  {
    value: PAYMENT_METHOD_SPOS_CARD,
    name: language.spos_card,
  },
  {
    value: PAYMENT_METHOD_SPOS_QRCODE,
    name: language.spos_qrcode,
  },
];
export const PAYMENT_METHOD_MERCHANT_ORDER_OBJ = convertArrToObj(
  PAYMENT_METHOD_MERCHANT_ORDER,
  "value"
);

export const STATUS_MERCHANT_ORDER_DEFAULT = {
  value: "",
  name: "",
};

export const PAYMENT_METHOD_MERCHANT_ORDER_DEFAULT = {
  value: "",
  name: "",
};
