import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_PARTNER } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const ServicePartner = React.lazy(
  () => import("containers/ServiceManagement/ServicePartner")
);

function ServicePartnerRoute() {
  return (
    <Route
      path="service-partner"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<ServicePartner />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_PARTNER]}
          />
        </Suspense>
      }
    />
  );
}

export default ServicePartnerRoute;
