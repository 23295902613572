import "moment/locale/eu";
import "moment/locale/vi";
import { Routes, Route } from "react-router-dom";
import React, { Suspense } from "react";
import { language } from "shared/language";
import PermissionDeniedPage from "components/PermissionDenied";
import Loading from "components/Loading";
import ConfigRoutes from "./configRoutes";
import ManagementRoutes from "./managementRoutes";

const Print = React.lazy(() => import("components/Print"));

const NormalLogin = React.lazy(() => import("containers/Auth/Login/LoginView"));

const NormalRegister = React.lazy(
  () => import("containers/Auth/Register/RegisterView")
);

const ForgotPassword = React.lazy(
  () => import("containers/Auth/ForgotPassword/ForgotPasswordView")
);

const ResetPassword = React.lazy(
  () => import("containers/Auth/ResetPassword/ResetPasswordView")
);

const ApplicationComponent = React.lazy(() => import("containers/App"));

function RootRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Loading loading />}>
            <ApplicationComponent />
          </Suspense>
        }
      >
        {ManagementRoutes()}
        {ConfigRoutes()}
      </Route>
      <Route
        path="/print"
        element={
          <Suspense fallback={<Loading loading />}>
            <Print />
          </Suspense>
        }
      />
      <Route
        path="/login"
        element={
          <Suspense fallback={<Loading loading />}>
            <NormalLogin />
          </Suspense>
        }
      />
      <Route
        path="/register"
        element={
          <Suspense fallback={<Loading loading />}>
            <NormalRegister />
          </Suspense>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <Suspense fallback={<Loading loading />}>
            <ForgotPassword />
          </Suspense>
        }
      />

      <Route
        path="/reset-password"
        element={
          <Suspense fallback={<Loading loading />}>
            <ResetPassword />
          </Suspense>
        }
      />

      <Route
        path="*"
        element={
          <PermissionDeniedPage
            code="404"
            description={language.not_found_page}
          />
        }
      />
    </Routes>
  );
}

export default RootRoutes;
