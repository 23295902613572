import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import PermissionDeniedPage from "components/PermissionDenied";
import Loading from "components/Loading";
import Permission from "components/Permission";
import { MODULE_UNIT_GROUP } from "shared/constants";

const ConfigModuleForBuilding = React.lazy(
  () => import("containers/ProjectManagement/ConfigBuilding")
);

function ConfigModuleForBuildingRoute() {
  return (
    <Route
      path="building/:idBuilding/config"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<ConfigModuleForBuilding />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_UNIT_GROUP]}
          />
        </Suspense>
      }
    />
  );
}

export default ConfigModuleForBuildingRoute;
