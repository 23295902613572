import moment from 'moment';

const intersectPeriodTime = (firstPeriodTime: Date[] = [], secondPeriodTime: Date[] = []): boolean => {
  if (
    firstPeriodTime.length === 0 ||
    secondPeriodTime.length === 0 ||
    firstPeriodTime.length + secondPeriodTime.length === 1 ||
    firstPeriodTime.length > 2 ||
    secondPeriodTime.length > 2
  ) {
    return false;
  }
  const startTimeOfFirstPeriodTime = moment(firstPeriodTime?.[0]);
  const endTimeOfFirstPeriodTime = moment(firstPeriodTime?.[1] ?? moment(startTimeOfFirstPeriodTime).endOf('day'));
  const startTimeOfSecondPeriodTime = moment(secondPeriodTime?.[0]);
  const endTimeOfSecondPeriodTime = moment(secondPeriodTime?.[1] ?? moment(startTimeOfSecondPeriodTime).endOf('day'));
  if (startTimeOfFirstPeriodTime.isAfter(endTimeOfFirstPeriodTime)) {
    return false;
  }
  if (startTimeOfSecondPeriodTime.isAfter(endTimeOfSecondPeriodTime)) {
    return false;
  }
  if (
    endTimeOfFirstPeriodTime.isSameOrBefore(startTimeOfSecondPeriodTime) ||
    startTimeOfFirstPeriodTime.isSameOrAfter(endTimeOfSecondPeriodTime)
  ) {
    return false;
  }
  return true;
};

export default intersectPeriodTime;
