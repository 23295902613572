import { language } from "shared/language";
import { convertArrToObj } from "shared/utils";

export const BILL_DEFAULT_FILTER = {
  unitGroupId: undefined,
  unitId: undefined,
  code: undefined,
};

export const STATUS_BILL_UNPAID = "UNPAID";
export const STATUS_BILL_PAID = "PAID";
export const STATUS_BILL_IN_PROCESS = "IN_PROCESS";
export const STATUS_BILL_DISABLED = "DISABLED";
export const STATUS_BILL_EMPTY = "EMPTY";

export const STATUS_BILL = [
  {
    value: STATUS_BILL_UNPAID,
    name: language.unpaid,
    color: "red",
  },
  {
    value: STATUS_BILL_PAID,
    name: language.paid,
    color: "green",
  },
  {
    value: STATUS_BILL_IN_PROCESS,
    name: language.bill_in_process,
    color: "red",
  },
  {
    value: STATUS_BILL_DISABLED,
    name: language.disable,
    color: "red",
  },
  {
    value: STATUS_BILL_EMPTY,
    name: language.empty,
    color: "blue",
  },
];
export const STATUS_BILL_OBJ = convertArrToObj(
  STATUS_BILL,
  "value"
);

export const STATUS_BILL_DEFAULT = {
  value: "",
  name: "",
  color: "",
}

export const PAYMENT_METHOD_EMPTY = "EMPTY";
export const PAYMENT_METHOD_UNPAID = "UNPAID";
export const PAYMENT_METHOD_IN_PROCESS = "IN_PROCESS";
export const PAYMENT_METHOD_PAID = "PAID";
export const PAYMENT_METHOD_PARTIAL_PAID = "PARTIAL_PAID";

export const PAYMENT_METHOD = [
  {
    value: PAYMENT_METHOD_EMPTY,
    name: language.empty,
    color: "red",
  },
  {
    value: PAYMENT_METHOD_UNPAID,
    name: language.unpaid,
    color: "red",
  },
  {
    value: PAYMENT_METHOD_IN_PROCESS,
    name: language.in_process,
    color: "blue",
  },
  {
    value: PAYMENT_METHOD_PAID,
    name: language.paid,
    color: "green",
  },
  {
    value: PAYMENT_METHOD_PARTIAL_PAID,
    name: language.partial_paid,
    color: "blue",
  },
];
export const PAYMENT_METHOD_OBJ = convertArrToObj(
  PAYMENT_METHOD,
  "value"
);

export const PAYMENT_METHOD_DEFAULT = {
  value: "",
  name: "",
  color: "",
}

export const BILL_TYPE_FULL_MONTH = 'FULL_MONTH';
export const BILL_TYPE_PART_OF_MONTH = 'PART_OF_MONTH';
export const BILL_TYPE_CUSTOM_HALF_OF_MONTH = 'CUSTOM_HALF_OF_MONTH';
export const BILL_TYPE_ADJUST_UP = 'ADJUST_UP';
export const BILL_TYPE_ADJUST_DOWN = 'ADJUST_DOWN';
export const BILL_TYPE_INTEREST = 'INTEREST';
export const BILL_TYPE_CARD = 'CARD';
export const BILL_TYPE_UTILITY = 'UTILITY';
export const BILL_TYPE_UTILITY_SURCHARGE = 'UTILITY_SURCHARGE';

export const BILL_TYPE = [
  { id: BILL_TYPE_FULL_MONTH, name: language.bill_full_month },
  { id: BILL_TYPE_PART_OF_MONTH, name: language.bill_part_of_month },
  { id: BILL_TYPE_CUSTOM_HALF_OF_MONTH, name: language.bill_half_of_month },
  { id: BILL_TYPE_ADJUST_UP, name: language.adjust_up },
  { id: BILL_TYPE_ADJUST_DOWN, name: language.adjust_down },
  { id: BILL_TYPE_INTEREST, name: language.interest },
  { id: BILL_TYPE_CARD, name: language.card },
  { id: BILL_TYPE_UTILITY, name: language.utility },
  { id: BILL_TYPE_UTILITY_SURCHARGE, name: language.utility_surcharge },
];

export const BILL_TYPE_DEFAULT = {
  id: "",
  name: "",
}

export const BILL_TYPE_OBJ = convertArrToObj(BILL_TYPE, 'id');

export const SERVICE_TYPE_SAVE_FROM_TO = 'SAVE_FROM_TO';
export const SERVICE_TYPE_SAVE_NUMBER_USE = 'SAVE_NUMBER_USE';
export const SERVICE_TYPE_REGISTER_ONE_TIME = 'REGISTER_ONE_TIME';
export const SERVICE_TYPE_REGISTER_LONG_TERM = 'REGISTER_LONG_TERM';

export const SERVICE_TYPE = [
  { value: SERVICE_TYPE_SAVE_FROM_TO, name: language.save_from_to },
  { value: SERVICE_TYPE_SAVE_NUMBER_USE, name: language.save_number_use },
  { value: SERVICE_TYPE_REGISTER_ONE_TIME, name: language.register_one_time },
  { value: SERVICE_TYPE_REGISTER_LONG_TERM, name: language.register_long_term },
];

export const SERVICE_TYPE_DEFAULT = {
  value: "",
  name: "",
}

export const SERVICE_TYPE_OBJ = convertArrToObj(SERVICE_TYPE, 'value');
