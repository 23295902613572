import { language } from "shared/language";
import { convertArrToObj } from "shared/utils";

export const MAX_PRICE = 2000000000;

export const MERCHANT_UTILITY_TYPE_RESERVATION = "RESERVATION";

export const MERCHANT_UTILITY_DEFAULT_FILTER = {
  utilityPartnerId: undefined,
  name: undefined,
  unitGroupId: undefined,
  activeStartDate: undefined,
};

export const MERCHANT_UTILITY_TIME_FRAME_ARRAY_15_MINUTE = 15;
export const MERCHANT_UTILITY_TIME_FRAME_ARRAY_30_MINUTE = 30;
export const MERCHANT_UTILITY_TIME_FRAME_ARRAY_1_HOUR = 60;

export const MERCHANT_UTILITY_TIME_FRAME_ARRAY = [
  {
    label: language.merchant_utility_time_frame_array_15_minute,
    key: MERCHANT_UTILITY_TIME_FRAME_ARRAY_15_MINUTE,
  },
  {
    label: language.merchant_utility_time_frame_array_30_minute,
    key: MERCHANT_UTILITY_TIME_FRAME_ARRAY_30_MINUTE,
  },
  {
    label: language.merchant_utility_time_frame_array_1_hour,
    key: MERCHANT_UTILITY_TIME_FRAME_ARRAY_1_HOUR,
  },
];

export const MERCHANT_UTILITY_SERVE_DAY_SUNDAY = 0;
export const MERCHANT_UTILITY_SERVE_DAY_MONDAY = 1;
export const MERCHANT_UTILITY_SERVE_DAY_TUESDAY = 2;
export const MERCHANT_UTILITY_SERVE_DAY_WEDNESDAY = 3;
export const MERCHANT_UTILITY_SERVE_DAY_THURSDAY = 4;
export const MERCHANT_UTILITY_SERVE_DAY_FRIDAY = 5;
export const MERCHANT_UTILITY_SERVE_DAY_SATURDAY = 6;

export const MONDAY_KEY = "MONDAY";
export const TUESDAY_KEY = "TUESDAY";
export const WEDNESDAY_KEY = "WEDNESDAY";
export const THURSDAY_KEY = "THURSDAY";
export const FRIDAY_KEY = "FRIDAY";
export const SATURDAY_KEY = "SATURDAY";
export const SUNDAY_KEY = "SUNDAY";

export const MERCHANT_UTILITY_SERVE_DAY_ARRAY = [
  {
    label: language.merchant_utility_serve_day_monday,
    key: MERCHANT_UTILITY_SERVE_DAY_MONDAY,
    id: MONDAY_KEY,
  },
  {
    label: language.merchant_utility_serve_day_tuesday,
    key: MERCHANT_UTILITY_SERVE_DAY_TUESDAY,
    id: TUESDAY_KEY,
  },
  {
    label: language.merchant_utility_serve_day_wednesday,
    key: MERCHANT_UTILITY_SERVE_DAY_WEDNESDAY,
    id: WEDNESDAY_KEY,
  },
  {
    label: language.merchant_utility_serve_day_thursday,
    key: MERCHANT_UTILITY_SERVE_DAY_THURSDAY,
    id: THURSDAY_KEY,
  },
  {
    label: language.merchant_utility_serve_day_friday,
    key: MERCHANT_UTILITY_SERVE_DAY_FRIDAY,
    id: FRIDAY_KEY,
  },
  {
    label: language.merchant_utility_serve_day_saturday,
    key: MERCHANT_UTILITY_SERVE_DAY_SATURDAY,
    id: SATURDAY_KEY,
  },
  {
    label: language.merchant_utility_serve_day_sunday,
    key: MERCHANT_UTILITY_SERVE_DAY_SUNDAY,
    id: SUNDAY_KEY,
  },
];

export const MERCHANT_UTILITY_SERVE_DAY_ID = convertArrToObj(
  MERCHANT_UTILITY_SERVE_DAY_ARRAY,
  "key"
);

export const UTILITY_TYPE_PRE_ORDER = "PRE_ORDER";

export const UTILITY_TYPE_ARRAY = [
  {
    id: UTILITY_TYPE_PRE_ORDER,
    value: language.pre_order,
  },
];
