import {
  API_TYPE_CALL_API,
  METHOD,
  SERVER,
  X_UNIT_GROUP_ID,
} from "shared/constants";
import api from "./api-config";

const AUTH_SERVER = `${SERVER}/cms/auth`;
const USER_SERVER = `${SERVER}/cms/users`;
const REGISTER = `${AUTH_SERVER}/register`;
const LOGIN = `${AUTH_SERVER}/login`;
const FORGOT_PASSWORD = `${USER_SERVER}/forgot-password`;
const RESET_PASSWORD = `${USER_SERVER}/reset-password`;
const PROFILE = `${USER_SERVER}/profile`;
const GET_PERMISSION = `${AUTH_SERVER}/get-permissions`;
const LOGOUT = `${AUTH_SERVER}/AUTH_SERVER/logout`;
const PASSWORD_UPDATE = `${USER_SERVER}/change-password`;

export type TUserRegisterServiceInput = {
  fullName?: string;
  phone?: string | null;
  email: string;
  password: string;
};

export type TUserLoginServiceInput = {
  password: string;
  email: string;
};

export type TUserSetNewPasswordServiceInput = {
  email: string;
};

export type TUserLogoutServiceInput = {
  deviceToken: string;
};

export type TUpdateProfileServiceInput = {
  fullName: string;
  phone?: string;
  email?: string;
  birthday?: Date;
  avatar?: string;
  gender?: string;
};

export type TUpdatePasswordServiceInput = {
  oldPassword: string;
  newPassword: string;
};

export type TGetPermissionService = {
  filter: { name: string };
  idBuilding: string;
};

export async function registerService(user: TUserRegisterServiceInput) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: REGISTER,
      body: { ...user },
      method: METHOD.POST,
    },
  });
  return response;
}

export async function loginService(data: TUserLoginServiceInput) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: LOGIN,
      body: { ...data },
      method: METHOD.POST,
    },
  });
  return response;
}

export async function logoutService(data: TUserLogoutServiceInput) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: LOGOUT,
      body: { ...data },
      method: METHOD.POST,
    },
  });
  return response;
}

export async function forgotPasswordService(email: string) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: FORGOT_PASSWORD,
      body: {
        email,
      },
      method: METHOD.POST,
    },
  });
  return response;
}

export async function resetPasswordService(
  accessToken: string,
  password: string
) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      accessToken,
      apiUrl: RESET_PASSWORD,
      body: {
        password,
      },
      method: METHOD.POST,
    },
  });
  return response;
}

export async function updatePasswordService(data: TUpdatePasswordServiceInput) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: PASSWORD_UPDATE,
      body: {
        ...data,
      },
      method: METHOD.PUT,
    },
  });
  return response;
}

export async function getPermissionService({
  filter,
  idBuilding,
}: TGetPermissionService) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: GET_PERMISSION,
      method: METHOD.GET,
      params: {
        ...filter,
      },
      headers,
    },
  });
  return response;
}

export const updateProfileService = async (
  user: TUpdateProfileServiceInput
) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: PROFILE,
      body: {
        ...user,
      },
      method: METHOD.PUT,
    },
  });
  return response;
};

export const getProfileService = async () => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: PROFILE,
      method: METHOD.GET,
    },
  });
  return response;
};
