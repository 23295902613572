import parseJwt from "./parseJwt";

const isTokenExpired = (token: string) => {
  const decodedJwt = parseJwt(token);
  if (!decodedJwt) return true;
  if (decodedJwt.exp * 1000 < Date.now()) {
    return true;
  }
  return false;
};
export default isTokenExpired;
